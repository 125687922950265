
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, setDirectAccessibleData } from "../../../config";

@Component
export default class Login extends Vue {
  /* eslint-disable */
  public loginObj: any = {};
  public message = null;
  public passwordmessage = null;
  public raw: any = null;
  public data: any = {};
  public passwordFieldType = "password";
  public passwordField = "password";
  public inProcess = true;
  public expMsg = null;
  public link: any = null;

  public async userLogin() {
    try {
      this.inProcess = false;
      if (this.$route.query.id) this.loginObj.queryId = this.$route.query.id;
      const response = await Axios.post(
        `${BASE_API_URL}authentication/userLogin`,
        this.loginObj
      );

      if (response.status === 201) {
        // this.inProcess = true
        this.$snotify.success("Login Successfully");
        await this.deleteToken(response.data.returnObj.userId);
        await this.$store.dispatch("setSessionData", response.data.returnObj);
        await setDirectAccessibleData();
        if (response.data.returnObj.userType === 1)
          this.$router.push("/superadmin-dashboard");
        if (response.data.returnObj.userType === 6)
          this.$router.push("/developer-dashboard");
        if (response.data.returnObj.userType === 3)
          this.$router.push("/processor-dashboard");

        if (response.data.returnObj.userType === 2) {
          if (
            this.$store.state.sessionObj.switchToCondition &&
            this.$store.state.sessionObj.tempLoanId
          ) {
            this.$router.push({
              path: "/loan-process",
              query: {
                id: this.$store.state.sessionObj.tempLoanId,
                conditionDoc: "Yes",
              },
            });
          } else if (response.data.isOnBoardingCompleted)
            this.$router.push("/broker-dashboard");
          else
            this.$router.push({
              path: "/vendor-credentials",
              query: { id: this.$store.state.sessionObj.userId },
            });
        }

        // TITLE
        if (response.data.returnObj.userType === 7) {
          if (localStorage.getItem("previousLink")) {
            this.$router.push(`${localStorage.getItem("previousLink")}`);
          } else {
            this.$router.push("/agent-dashboard");
          }
        }
        //TITLE
        //}

        if (response.data.returnObj.userType === 4) {
          if (
            this.$store.state.sessionObj.switchToCondition &&
            this.$store.state.sessionObj.tempLoanId
          ) {
            this.$router.push({
              path: "/borrower-loanDetails",
              query: {
                id: this.$store.state.sessionObj.tempLoanId,
                conditionDoc: "Yes",
              },
            });
          } else if (response.data.returnObj.loanLength == 1) {
            this.$router.push({
              path: "/borrower-loanDetails",
              query: { id: response.data.returnObj.loanId },
            });
          } else {
            this.$router.push("/borrower-dashboard");
          }
        }
      } else {
        this.message = response.data.message;
        this.passwordmessage = response.data.passwordmessage;
        if (response.data && response.data.userData)
          this.raw = response.data.userData;
      }
      this.inProcess = true;
    } catch (error) {
      console.log(error);
    }
  }

  public trimEmail() {
    this.loginObj.email = this.loginObj.email.trim();
  }

  public async verifyRegisterdUser() {
    try {
      const res = await Axios.post(
        BASE_API_URL + "authentication/verifyRegisterToken",
        {
          token: this.$route.query.id,
        }
      );
      if (res.status == 201) {
        if (res.data.userInfo.userType === 2) {
          if (res.data.userInfo.createdBy) {
            this.loginObj = res.data.userInfo;
            this.raw = res.data;
          } else {
            await this.$store.dispatch("setSessionData", res.data.userInfo);
            if (res.data.isOnBoardingCompleted)
              this.$router.push("/broker-dashboard");
            else
              this.$router.push({
                path: "/vendor-credentials",
                query: { id: this.$store.state.sessionObj.userId },
              });
          }
        } else {
          this.loginObj = res.data.userInfo;
          this.raw = res.data;
        }
      } else {
        this.expMsg = res.data.message;
        // this.$snotify.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }



public async deleteToken(userId) {
    try {
      const res = await Axios.post(
        BASE_API_URL + "authentication/deleteToken",
        {
          userId: userId,
        }
      );
      if (res.status == 201) {
       
        }
    } catch (error) {
      console.log(error);
    }
  }

  public async resendRegisterToken() {
    try {
      const res = await Axios.post(
        BASE_API_URL + "common/resendRegisterToken",
        {
          userId: this.raw._id,
        }
      );
      if (res.status == 200) {
        this.$snotify.success(res.data.message);
      } else if (res.status == 400) this.$snotify.error(res.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  public async sendVerificationLinkToBorrower(data) {
    this.$store.state.tmpcoLoader = true;
    try {
      const forServer = {
        email: data.email,
        loanId: data.loanId,
        userId: data._id,
        fullName: data.name,
      };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/resendVerificationLinkToBorrower`,
        forServer
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public switchVisibility() {
    this.passwordFieldType =
      this.passwordFieldType === "password" ? "text" : "password";
  }

  public switchFun() {
    this.passwordField =
      this.passwordField === "password" ? "text" : "password";
  }

  mounted() {
    //                     // console.log('HISTORY--->',this.$router.history)
    //  console.log('HISTORY--->',this.$router.history)
    //  console.log('HIST 5--->',window.history)
    document.title = this.$route.meta.title;
    if (this.$route.query.id) {
      this.verifyRegisterdUser();
    }
    if (this.$route.query.link) {
      this.link = this.$route.query.link;
      localStorage.setItem("previousLink", this.link);
    }
  }
}
